/**
 * External dependencies
 */
 import { useStaticQuery, graphql } from "gatsby"

 const useAreaGuideGallery = () => {
   const {
     allFile: { nodes: items },
   } = useStaticQuery(graphql`
      query AreaGuideGallery {
        allFile(
          filter: { relativeDirectory: { eq: "area-guide-gallery/images" } }
        ) {
          nodes {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    `)
 
   return items
 }
 
 export default useAreaGuideGallery
 