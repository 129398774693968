/**
 * External dependencies
 */
import React, { useMemo, useState, useEffect } from "react"
import { Link } from "gatsby"
import axios from "axios";
import { Row, Col, Container } from "react-bootstrap"

/**
 * Internal dependencies
 */
import useHomeProperties from "./use-home-properties"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import Card from "../card/card"
import LinkIcon from "../link-icon/link-icon"
import properties from "./static/properties"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import "./styles/_index.scss"
import Carousel from "../carousel/carousel"
import classNames from "classnames"
import { currencyFormat } from '@components/common/utils';
import Modal from 'react-bootstrap/Modal';
import BookAViewingForm from "../../Forms/BookAViewing"
import {
  isIOS,
  isMobile
} from "react-device-detect";
import PropertyEnquireForm from "../../Forms/PropertyEnquire";
import Noimg from "../no-img/Noimg";
const SimilarProperties = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [index, setIndex] = useState();
  const openform = (i) => {
            setShowForm(!showForm)
          setIndex(i)
  }
  const [propItems, setPropItems] = useState([])

  const getitems = async url => {
      try {
          const { data } = await axios.get(url, {baseURL: process.env.GATSBY_STRAPI_FORM_URL,
            headers: {
              Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
              // "Content-Type": "multipart/form-data"  
            }})
          // console.log("PropertySimilar", data)
          setPropItems(data)
      } catch (error) {
          // console.error(error)
      }
  }

  useEffect(() => {
      let url =
      process.env.GATSBY_STRAPI_SRC +
      "/stb-lists/item/Property-details?pid=" +
      props?.data?.id

      getitems(url)
  }, [props?.data?.id]);

  const propertiesCollection = useMemo(
    () =>
      propItems.map((x, i) => {
        let uriStr = ""
        let mysale = ""
        let hit = { ...x }
        // mapping
        hit.searchType = hit.searchType || hit.search_type;
        if (hit.department === "auction") {
          uriStr = `auction-property-for-sale/`
          mysale = `myResSaleProp`
        } else if (hit.searchType === "sales" && hit.department === "residential") {
          uriStr = `property-for-sale/`
          mysale = `myResSaleProp`
        } else if (
          hit.searchType === "lettings" &&
          hit.department === "residential"
        ) {
          uriStr = `property-to-rent/`
          mysale = `myResRentProp`
        }

        let processedImages = JSON.stringify({});
        if (x.imagetransforms?.images_Transforms) {
          processedImages = x.imagetransforms?.images_Transforms;
        }
        return (
          <Card>
            <Link to={`/${uriStr}${x.slug}-${x.id}`}>
              <div className="similar-properties-badge">{x?.status}</div>
              {/* <Card.Head onImageHoverZoom image={images[x.image]} alt={x.alt || "property"} /> */}
              <div className="overflow-hidden">
                <div className="hover-zoom">
               {x?.images[0]?.url?
              <ImageTransform
                imagesources={x?.images[0]?.url}
                // renderer="srcSet"
                imagename="property.images.similardetail"
                attr={{ alt: `${x.display_address?x.display_address:""} - Strakers Estate Agents`, className: "" }}
                imagetransformresult={processedImages}
                id={x.id}
              />
              :
              <Noimg imagename="coming-soon-image.Image.detail"/>

               }
              </div>
              </div>
            </Link>
            <Card.Body>
              <Link className="address-min-height" to={`/${uriStr}${x.slug}-${x.id}`}>
                <h5>{x.display_address}</h5>
                <h5>{x.area}</h5>
              </Link>

              <div className="card__description  d-flex">
              {x?.price_qualifier !== "Default" && x?.price_qualifier &&
                <span className="card__subtitle fz14 d-inline-block">
                  {x?.price_qualifier !== "Default" ? x?.price_qualifier : ""}
                </span>
                }
                <span className="fw-bolder card__price fz14">{currencyFormat(x?.price, '£', false)}</span>
              </div>

              <p>{x?.title?.replace("land", "Land")?.replace(/-/g, ' ')}</p>
            </Card.Body>
            <Card.Footer>
              <Row className="g-0 flex-nowrap justify-content-between">
                <Col>
                  <Link class="link-icon blue-link-hover d-flex align-items-center"
                    to={`/${uriStr}${x.slug}-${x.id}`}
                  >
                    <div
                      className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                    >
                      Details
                    </div>
                    <i className={classNames(`icon icon--eye`)}></i>
                  </Link>
                  {/* <LinkIcon text="Details" icon="eye" /> */}
                </Col>

                <Col>
                  <Card.Delimiter />
                </Col>

                <Col>
                  <a class="link-icon blue-link-hover d-flex align-items-center"
                  // to={`/${uriStr}${hit.slug}-${hit.id}`}
                  onClick={(e) => openform(x)}
                  href="javascript:void(0)"
                  >
                    <div
                      className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                    >
                      Enquire
                    </div>
                    <i className={classNames(`icon icon--arrow-up-right`)}></i>
                  </a>
                  {/* <LinkIcon text="Enquire" /> */}
                </Col>
              </Row>
            </Card.Footer>
          
          </Card>
        )
      }),
    [propItems]
  )

  return (
    propItems.length > 0 &&
    <div className="home-properties similar-properties">
      <Container className="home-properties__inner">
        {/* <h6 className="home-properties__quote fw-bolder fz14 d-inline-block">
          {props.Cap_Title}
        </h6> */}

        <Row className="align-items-center align-items-lg-start justify-content-lg-between">
          <Col xs={12} lg="auto">
            <h3>You might also be interested in...</h3>
          </Col>

          {/* <Col lg="auto">
            <Row className="flex-nowrap home-properties__actions">
              <Col xs="auto" className="home-properties__action-col-1">
                <LinkIcon href="/" text="Property for sale" />
              </Col>

              <Col xs="auto">
                <LinkIcon href="/" text="Property to rent" />
              </Col>
            </Row>
          </Col> */}
        </Row>
      </Container>
          {/* {JSON.stringify(props.data?.id)} */}
      <Carousel
        className="home-properties__collection"
        data={propertiesCollection}
        controllers={{ buttons: isMobile && propertiesCollection?.length > 1 ? true : !isMobile && propertiesCollection?.length > 4 ? true : false }}
        isFluidContainer={false}
      />
       <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false);setIndex() }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>Enquire about Property</h4>
          {/* {JSON.stringify(index)}
          {index?.searchtype} */}
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <PropertyEnquireForm
                                                id={index?.id}
                                                 property_img={index?.images && index?.images[0]?.url}
                                                  page_url={`${process.env.GATSBY_SITE_URL}${index?.search_type === "sales" && index?.department === "residential" ? "property-for-sale/" : "property-to-rent/"}${index?.slug}-${index?.id}`}
                                                   property_title={index?.display_address}
                                                    type={index?.search_type}
                                                data={index}
                                                />  
                                            </Modal.Body>
</Modal>
       
    </div>
  )
}

export default SimilarProperties
