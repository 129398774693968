/**
 * External dependencies
 */
import React from "react"

/**
 * Internal dependencies
 */
import Breadcrumbs from "../breadcrumbs/breadcrumbs"

import "./styles/_index.scss"
import {
  isIOS,
  isMobileOnly
} from "react-device-detect";
const DevelopmentDetailBreadcrumbs = () => (
  <Breadcrumbs
    className="development-detail-breadcrumbs"
    back={true}
    text="Back to Search Results"
    social={isMobileOnly ? true : false}
    hideSocialOnMobile={false}
    hideSocialOnDesktop={true}
  />
)

export default DevelopmentDetailBreadcrumbs
