/**
 * External dependencies
 */
 import { useStaticQuery, graphql } from "gatsby";

 const useGetDevelopmentDetailBanner = () => {
   const {
     allFile: { nodes: items },
   } = useStaticQuery(graphql`
     query DevelopmentIntro {
       allFile(
         filter: { relativeDirectory: { eq: "development-detail-banner/images" } }
       ) {
         nodes {
           relativePath
           childImageSharp {
             gatsbyImageData(layout: FULL_WIDTH)
           }
         }
       }
     }
   `)
 
   return items;
 }
 
 export default useGetDevelopmentDetailBanner
 