/**
 * External dependencies.
 */
import classNames from 'classnames';
import React, { useState } from 'react'
import { Container, Navbar } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
/**
 * Internal dependencies.
 */
import './styles/_index.scss';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactPlayer from "react-player"
import Portal from "../portal/portal"
import { navigate } from "@reach/router"
import ShareToFriendForm from "../../Forms/ShareToFriend";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
const navData = [
  {
    text: 'Details'
  },
  {
    text: 'Gallery'
  },
  {
    text: 'Video Tour'
  },
  {
    text: 'Availability'
  },
  {
    text: 'Siteplan'
  },
  {
    text: 'Map'
  },
  {
    text: 'Brochure'
  },
  {
    text: 'Share'
  }
];

const ActiveNav = (props) => {
  const [active, setIsActive] = useState("Details");

   // Property images lightbox
   const [photoIndex, setPhotoIndex] = useState(0);
   const [isOpen, setIsOpen] = useState(false);
 
   const openPropertyImage = (e, ind) => {
     e.preventDefault();
     setPhotoIndex(ind);
     setIsOpen(true);
   }
       var propertyLightImages = props.data?.images && props.data?.images?.map(img => img.url);
   // Property images lightbox


//Video tour

const [isPortalOpen, setIsPortalOpen] = useState(false)
  const [play, setPlayVideo] = useState(false)
  const playVideo = () => setPlayVideo(true)

  const handleClick = () => {
    setIsPortalOpen(true)
    playVideo()
  }

// video tour

    // Property floorplan images lightbox
    const floorplanImages = props.data?.floorplan
    const [floorIndex, setFloorIndex] = useState(0);
    const [floorisOpen, setIsFloorOpen] = useState(false);

    const openFloorImage = (e, ind) => {
        e.preventDefault();
        setFloorIndex(ind);
        setIsFloorOpen(true);
    }

    var propertyLightImagesFloorplan = floorplanImages && floorplanImages?.map(img => img.url);
    // Property floorplan images lightbox


       // Social share
      
       const shareurl = typeof window !== 'undefined' ? window.location.href : ''
       // const viewingBookingUrl = get(props, 'propertyData.extra.viewing_booking_url', false)
   
       const [Shareicons,setShareicons] = React.useState(false);
   
       const openShareicons = () => {
           setShareicons(true);
           if(Shareicons === true) {
           setShareicons(false);
           }
       }
 
       const trackerShare = (event) => {
         window.dataLayer = window.dataLayer || [];
         window.dataLayer.push({
         'event': 'Share - social icons',
         'formType': event + ' - ' +shareurl,
         'formId': 'Share',
         'formName': 'Share',
         'formLabel': 'Share'
         });
     }
     // Social share

     const [modalSharefrndformOpen,setSharefrndformOpen] = React.useState(false);
     const openSharefrndformModal = () => {
       setSharefrndformOpen(true);
     }
     const closeSharefrndformModal = () => {
       setSharefrndformOpen(false);
     }
  return (
    <div className="active-nav">
      <Container>
        <Navbar>
        <button
            key={'Details'}
            className={classNames('clear-btn', {'is-active': "Details" === active})}
            onClick={() => {
              setIsActive('Details')
            }}
            >
              Details
          </button>
          {props?.data?.images?.length > 1 &&
          <button
            key={'Gallery'}
            className={classNames('clear-btn', {'is-active': "Gallery" === active})}
            onClick={(e) => {
              setIsActive('Gallery'); props?.executeScrollGallery()
            }}
            >
              Gallery
          </button>
          }
          {props?.data?.video_link &&
          <button
            key={'Video Tour'}
            className={classNames('clear-btn', {'is-active': "Video Tour" === active})}
            onClick={(e) => {
              setIsActive('Video Tour');handleClick()
            }}
            >
              Video Tour
          </button>
}
          <button
            key={'Availability'}
            className={classNames('clear-btn', {'is-active': "Availability" === active})}
            onClick={() => {
              setIsActive('Availability'); props?.executeScrollProperty()
            }}
            >
              Availability
          </button>
          {props.data?.floorplan?.length > 0 &&
          <button
            key={'Siteplan'}
            className={classNames('clear-btn', {'is-active': "Siteplan" === active})}
            onClick={(e) => {
              setIsActive('Siteplan'); openFloorImage(e, 0)
            }}
            >
              Siteplan
          </button>}
          {props?.data?.latitude && props?.data?.longitude &&
          <button
            key={'Map'}
            className={classNames('clear-btn', {'is-active': "Map" === active})}
            onClick={() => {
              setIsActive('Map');props?.executeScroll()
            }}
            >
              Map
          </button>
          }
          {props.data?.brochure?.length > 0 &&
          <button
            key={'Brochure'}
            className={classNames('clear-btn', {'is-active': "Brochure" === active})}
            onClick={() => {
              setIsActive('Brochure'); window?.open(props?.data?.brochure[0]?.url, '_blank')
            }}
            >
              Brochure
          </button>
          }
          <button
            key={'Share'}
            className={classNames('d-none d-md-flex clear-btn', {'is-active': "Share" === active})}
            onClick={(e) => {
              setIsActive('Share'); openShareicons()
            }}
            >
              Share
          </button>
          {/* {navData.map((x, index) => <button
            key={x.text}
            className={classNames('clear-btn', {'is-active': index === active})}
            onClick={() => {
              setIsActive(index)
            }}
            >
              {x.text}
          </button>)} */}
          {Shareicons &&
                                            <div onClick={openShareicons} className="property-share-social-icons-dev mobile-details-socila-share">
                                            <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                                <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                            </FacebookShareButton>
                                            <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                                <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                            </TwitterShareButton>
                                            <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                                <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                            </LinkedinShareButton>
                                            <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                                                <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                            </WhatsappShareButton>
                                            <EmailShareButton url='#' onClick={()=> { openSharefrndformModal();trackerShare('SendToFriend')}} className="my-share-button email-share">
                                                <EmailIcon size={32} round={false} borderRadius={`10`} />
                                            </EmailShareButton>
                                            </div>
                                        }
        </Navbar>
        
      </Container>
       {/* Property image lightbox */}
       {isOpen && (
            <Lightbox
              mainSrc={propertyLightImages[photoIndex]}
              nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
              prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
              }
            />
          )}
          {/* Property image lightbox */}

 {/* Property floorplan image lightbox */}
 {floorisOpen && (
                    <Lightbox
                        mainSrc={propertyLightImagesFloorplan[floorIndex]}
                        nextSrc={floorplanImages.length > 1 ? propertyLightImagesFloorplan[(floorIndex + 1) % propertyLightImagesFloorplan.length] : undefined}
                        prevSrc={floorplanImages.length > 1 ? propertyLightImagesFloorplan[(floorIndex + propertyLightImagesFloorplan.length - 1) % propertyLightImagesFloorplan.length] : undefined}
                        onCloseRequest={() => setIsFloorOpen(false)}
                        onMovePrevRequest={() =>
                            setFloorIndex((floorIndex + propertyLightImagesFloorplan.length - 1) % propertyLightImagesFloorplan.length)
                        }
                        onMoveNextRequest={() =>
                            setFloorIndex((floorIndex + 1) % propertyLightImagesFloorplan.length)
                        }
                    />
                )}
                {/* Property floorplan image lightbox */}
          <Portal
        onClose={() => {
          setIsPortalOpen(false)
        }}
        isOpen={isPortalOpen}
      >
        <ReactPlayer
          frameBorder="0"
          allowFullScreen
          url={props?.data?.video_link}
          modestbranding="1"
          controls={true}
          autoPlay={true}
          muted={false}
          playsinline={true}
          playing={play}
          width="100%"
          height="100vh"
          previewTabIndex={0}
          light={false}
        />
      </Portal>
      <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={modalSharefrndformOpen} onHide={() => { closeSharefrndformModal(false) }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>Share to a friend</h4>
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                              <ShareToFriendForm
                                              id={props.data?.id}
                                               property_img={props.data?.images[0]?.url}
                                                page_url={shareurl}
                                                 property_title={props.data?.display_address}
                                                  type={props.data?.search_type}
                                              data={props?.data}
                                              
                                              />
                                              
                                            </Modal.Body>

                                        </Modal>
    </div>
  )
}

export default ActiveNav
