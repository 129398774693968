/**
 * External dependencies.
 */
import React from "react"
import { Container } from "react-bootstrap"

/**
 * Internal dependencies.
 */
import "./styles/_index.scss"

const Banner = ({ children }) => <div className="banner">{children}</div>

const BannerImage = ({ children }) => (
  <div className="banner-image">{children}</div>
)

const BannerLogo = ({ children }) => (
  <div className="banner-logo">
    <Container>
      <div className="banner-logo__inner">{children}</div>
    </Container>
  </div>
)

Banner.Image = BannerImage
Banner.Logo = BannerLogo

export default Banner
