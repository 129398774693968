/**
 * External dependencies
 */
import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"

/**
 * Internal dependencies
 */
import useAreaGuideGallery from "./use-area-guide-gallery"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import VideoPlaceholder from "../video-placeholder/video-placeholder"
import "./styles/_index.scss"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import classNames from "classnames"
const NewHomesGallery = (props) => {
  const data = useAreaGuideGallery()
  // const images = convertGatsbyImageNodes(data)
  //  // Property images lightbox
   const [photoIndex, setPhotoIndex] = useState(0);
   const [isOpen, setIsOpen] = useState(false);
 
   const openPropertyImage = (e, ind) => {
     e.preventDefault();
     setPhotoIndex(ind);
     setIsOpen(true);
   }
       var propertyLightImages = props.Gallery && props.Gallery?.map(img => img.url);
  //  // Property images lightbox

  return (
    <div className="area-guide-gallery-wrapper">
      <Container className="area-guide-gallery container-big">
        <Row>
          {props?.Gallery?.slice(0, 3)?.map((item, i) =>{
             let processedImages = JSON.stringify({})
             if (props?.imagetransforms?.upload_images_Transforms) {
               processedImages = props.imagetransforms.upload_images_Transforms
             }
            return(
              <Col xs={12} md={6} lg={4} 
              className={classNames("area-guide-gallery__col new-home-gallery", {
                "d-none d-lg-block": i > 0,
              })}
              onClick={props?.Video_Url && i === 0 ? null : (e) => openPropertyImage(e, 0)}
>
              <VideoPlaceholder 

              image={item}
              video={i === 0 ? props?.Video_Url : null}
                    alt={item?.alternativeText?item?.alternativeText+"- Strakers Estate Agents":"Gallery New Homes - Strakers Estate Agents"}
                    imagetransforms={processedImages}
                    id={props.id}
                    Pagename={props.Title}
                    imgname={"offplan.upload_images.gallery"}
              
              />
            </Col>
  
            )
          })}

          {/* <Col
            xs={12}
            md={6}
            lg={4}
            className="area-guide-gallery__col"
          >
            <GatsbyImage image={images[2]} alt="background image" />
          </Col>

          <Col xs={12} lg={4} className="">
            <GatsbyImage image={images[3]} alt="background image" />
          </Col> */}
        </Row>
          {/* Property image lightbox */}
       {isOpen && (
            <Lightbox
              mainSrc={propertyLightImages[photoIndex]}
              nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
              prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
              }
            />
          )}
          {/* Property image lightbox */}
      </Container>
    </div>
  )
}

export default NewHomesGallery
