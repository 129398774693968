/**
 * External dependencies
 */
 import React, {useRef} from "react"

 /**
  * Internal dependencies
  */
 
 import Layout from "@components/layout"
 import Seo from "@components/seo"
 import { Col, Container, Row } from "react-bootstrap";
 import PropertyInfo from "@components/property-info/property-info"
 import LocRatingMap from '@components/map/loc-rating-map';
 import PropertySlider from "@components/property-slider/property-slider"
 import { currencyFormat } from "@components/common/utils";
 import PropertyActions from "@components/property-actions/property-actions"
 import Breadcrumbs from "@components/breadcrumbs/breadcrumbs"
 import { useQuery } from '@apollo/client';
 import logoBlack from "../images/svg/logo.svg"
import NotFoundPage from "../pages/404"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { navigate } from "@reach/router"
 import gql from "graphql-tag"
import SimilarProperties from "../components/home-properties/similar-properties";
import DevelopmentDetailBreadcrumbs from "../components/development-detail-breadcrumbs/development-detail-breadcrumbs"
import ActiveNav from "../components/active-nav/active-nav"
import DevelopmentDetailBanner from "../components/development-detail-banner/development-detail-banner"
import DevelopmentDetailInfo from "../components/development-detail-info/development-detail-info"
import NewHomesGallery from "../components/area-guide-gallery/new-homes-gallery";
import AvaliablityType from "../components/home-properties/avaliablity-type-properties";
 const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    offplans(where:{id: $id, publish:true}) {
      id
      office_crm_id
      price
      max_price
      Telephone
      display_address
      images
      availability_types(where:{publish:true, status: "For Sale"}) {
        price
        images
        title
        search_type
        slug
        status
        department
        imagetransforms
        id
        display_address
        area
        price_qualifier
      }
      imagetransforms
       description
      epc
      latitude
      longitude 
      location
      bedroom
      floorplan
			video_link
      brochure
      developer
      developer_logo {
        url
      }
      developement_type
    }
  }
`;
 const NewHomesDetails = (props) => {
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let myid_filt = pageurl.split('?');
    let mypageurl = myid_filt[0];
    let property_slug = mypageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");

    // debugger

    const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, { variables: { id: id },fetchPolicy: "no-cache" });

    

    var properties = null;
    if (property_details?.offplans?.length > 0) {
        properties = property_details?.offplans[0];
    }
    // console.log(properties)
    // if (properties === null) {
    //     navigate('/property/for-sale/')
    // }
    var action = "for sale";
    if (properties?.search_type == "lettings") {
        action = "to rent";
    }
    let mapItems = [];
    let mapItem = {};
    mapItem['lat']  = properties?.latitude
    mapItem['lng']  = properties?.longitude
    mapItem['id']  = properties?.id
    const mapMarkerImageShowFlag = 0;
    mapItems.push( mapItem );
    var action = "for sale"
    var metaTitle = `${properties?.bedroom} bedroom ${properties?.building?properties?.building:"property"} ${action} in ${properties?.display_address}`
    var metaDesc = `Know the details of ${properties?.bedroom} bedroom ${properties?.building?properties?.building:"property"} ${action} in ${properties?.display_address} with Strakers at ${currencyFormat(properties?.price, '£', false)}. Book a viewing to find a ${properties?.building?properties?.building:"property"} for you.`    

    // var metaTitle = `${properties?.title} in ${properties?.display_address}`
    // var metaDesc = `Know the details of ${properties?.title} in ${properties?.display_address} with Strakers at ${currencyFormat(properties?.price, '£', false)}. Book a viewing to get assistance in finding the right ${properties?.building} for you.`
    var searchData = {
        action: action,
        areas: (properties?.address?.town)? properties?.address?.town : properties?.address?.area,
        bedrooms: null,
        defaultArea: (properties?.address?.town)? properties?.address?.town : properties?.address?.area,
        maxPrice: null,
        minPrice: null,
        propertyType: null,
        search_type: properties?.search_type
    }
    const myRef = useRef(null)
    const executeScroll = () => {
        myRef?.current?.scrollIntoView({ behavior: 'smooth'})
    }
  const myRefGallery = useRef(null)
  const executeScrollGallery = () => {
    myRefGallery?.current?.scrollIntoView({ behavior: 'smooth'})
}
const myRefProperties = useRef(null)
const executeScrollProperty = () => {
  myRefProperties?.current?.scrollIntoView({ behavior: 'smooth'})
}

    if (loading)
  return (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading">
          <img
            className="logo-white loader-logo"
            src={logoBlack}
            alt="logo"
          />
        </div>
      </Container>
    </div>
  )

  console.log(properties,"properitrs")
  console.log(props.location,"locaaaa")
  return (
        property_details?.offplans?.length > 0 ? 
   <Layout
   type={"new-homes"}
   searchtype={"sales"}
   >
     <Seo 
      title={metaTitle}
      location={props.location}
      description={metaDesc}
      image={properties?.images[0]?.url ? properties?.images[0].url : null}
     />
     <DevelopmentDetailBreadcrumbs />
    <DevelopmentDetailBanner data={properties} />
    <ActiveNav executeScrollProperty={executeScrollProperty} executeScroll={executeScroll} executeScrollGallery={executeScrollGallery} data={properties} />
    <DevelopmentDetailInfo data={properties}  />
    {properties?.images?.length > 0 &&
    <><div  className="scroll-margin-top" ref={myRefGallery}></div>
    <NewHomesGallery Video_Url={properties?.video_link} imagetransforms={properties?.imagetransforms} id={properties?.id} Gallery={properties?.images?.slice(1)} />
    </>
 }
 {properties?.availability_types?.length > 0 &&
     <><div  className="scroll-margin-top" ref={myRefProperties}></div>
    
 <AvaliablityType Title={"Availability Types"} propItems={properties?.availability_types} />
 </>
 }
    {properties?.latitude && properties?.longitude &&
            <div className="property-details-landing-map" id="property-details-map">
              <div ref={myRef} className="scroll-margin-top"></div>
                                  <LocRatingMap data={mapItems} hasTitle={true} />
                              </div>
}
     {/* <Breadcrumbs Page={`${properties?.display_address}, ${(properties?.address?.town)? properties?.address?.town : properties?.address?.area}`}  alias={properties?.search_type} type={"property-details-page"} social={true} />
     <PropertySlider data={properties} />
     <PropertyInfo data={properties} />
     <PropertyActions data={properties} />
     <SimilarProperties data={properties} /> */}
   </Layout>
//  ) ;
 : (
    <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
  )
  )
    
}
 
 export default NewHomesDetails 