/**
 * External dependencies
 */
 import React, {useState} from "react"
import { Container, Row, Col } from "react-bootstrap"

/**
 * Internal dependencies
 */
 import ContentBlock from "../content/Content";
import Button from "../button/button"
import "./styles/_index.scss"
import { currencyFormat } from '@components/common/utils';
import Modal from 'react-bootstrap/Modal';
import RegisterIntrestForm from "../../Forms/RegisterIntrest"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
const DevelopmentDetailInfo = (props) =>  {
  const shareurl = typeof window !== 'undefined' ? window.location.href : ''

  const [showForm, setShowForm] = useState(false);
  const openform = () => {
          setShowForm(!showForm)
  }
  const GET_OFFICE_DETAIL = gql`
  query GetOfficeDetail($URL: String!) {
    offices(where: { Office_Crm_Id: $URL }) {
      id
      imagetransforms
      Office_Name
      Tail_Image {
        url
        alternativeText
      }
      Office_Address
      Office_Email
      URL
      Office_Telephone_Sales
      Office_Telephone_Lettings
      Office_Telephone_Progression
     
    }
  }
`
  const { loading, error, data } = useQuery(GET_OFFICE_DETAIL, {
    variables: { URL: props?.data?.office_crm_id },
  })
  return(
  <Container className="development-detail-info">
    <Row className="justify-content-between pb-48 pb-md-8_8 mt-2_4 mt-md-7_6">
      <Col className="development-detail-info__head" xs="12" lg="auto">
        <h1 className="new-home-det-dev">{props?.data?.developer}</h1>
        <p className="body-l mb-md-3_2">{props?.data?.display_address}</p>
        <p className="body-l mb-md-3_2">
          <strong>{currencyFormat(props?.data?.price, '£', false)} – {currencyFormat(props?.data?.max_price, '£', false)}</strong>
        </p>
        <Row className="development-detail-info__actions mb-6 d-none d-md-flex">
          <Col className="pe-2">
            <Button displayAsLink={false} color="yellow" onClick={openform}>
              <Button.Icon>
                <i className="icon icon--mail"></i>
              </Button.Icon>

              <Button.Text>Register your interest</Button.Text>
            </Button>
          </Col>
          <Col className="ps-2">
            <Button tel={props?.data?.Telephone}>
              <Button.Icon>
                <i className="icon icon--phone"></i>
              </Button.Icon>

              <Button.Text>{props?.data?.Telephone}</Button.Text>
            </Button>
          </Col>
        </Row>
      </Col>
      <Col className="development-detail-info__text" xs="12" lg="auto">
      <ContentBlock Content={props?.data?.description} />
      </Col>
    </Row>
    <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false) }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>Register your interest</h4>
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <RegisterIntrestForm
                                                to_admin={data?.offices[0]?.Office_Email?.toLowerCase()}
                                                developer={props.data?.developer}
                                                  page_url={shareurl}
                                                   property_title={props.data?.display_address}
                                                data={props?.data}/>  
                                            </Modal.Body>

                                        </Modal>
  </Container>
)
  }
export default DevelopmentDetailInfo
