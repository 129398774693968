/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import Banner from '../banner/banner';
import { GatsbyImage } from 'gatsby-plugin-image';
import useDevelopmentDetailBanner from './use-development-detail-banner'
import { convertGatsbyImageNodes } from '../../utils/gatsby-images';
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
const DevelopmentDetailBanner = (props) => {
  const data = useDevelopmentDetailBanner();
  const images = convertGatsbyImageNodes(data);
  let processedImages = JSON.stringify({});
  if (props.data?.imagetransforms?.upload_images_Transforms) {
      processedImages = props.data.imagetransforms.upload_images_Transforms;
  }

  return (
    <Banner>
      <Banner.Image>
        {/* <GatsbyImage image={images['feature-image']} alt="feature-image" /> */}
        <ImageTransform
                        imagesources={props?.data?.images[0]?.url}
                        renderer="srcSet"
                        imagename="offplan.upload_images.detailbig"
                        attr={{ alt: `${props.data?.display_address+" - Strakers Estate Agents"}`, class:'' }}
                        imagetransformresult={processedImages}
                        id={props.data?.id}
                      />
      </Banner.Image>
      {props?.data?.developer_logo?.url &&
      <Banner.Logo>
        {/* <GatsbyImage
          image={images['feature-logo']}
          alt="logo"
        /> */}
        <ImageTransform
                        imagesources={props?.data?.developer_logo?.url}
                        renderer="srcSet"
                        imagename="offplan.images.logo"
                        attr={{ alt: `${props.data?.developer+" - Strakers Estate Agents"}`, class:'' }}
                        imagetransformresult={processedImages}
                        id={props.data?.id}
                      />
      </Banner.Logo>}
    </Banner>
  )
}

export default DevelopmentDetailBanner